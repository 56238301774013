.ant-card .ant-card-body {
    padding: 8px;
}

.ant-select-single.ant-select-lg .ant-select-selector {
    border-radius: 0px;
}

.ant-select-selector {
    border-radius: 0px;
}

.point-input .ant-select, .ant-picker {
    width: 100%;
}
.point-input .ant-picker {
    border: none;
}

.point-input .ant-select .ant-select-selector {
    border: none;
}

.ant-select-selector {
    border: none;
}

.mobile-fc .ant-select .ant-select-selector {
    border: none;
}

.mobile-fc .ant-picker {
    border: none;
    width: 75%;
}

.mobile-fc .ant-cascader {
    width: 75%;
}

/* .ant-picker-date-panel {
    background-color: white;
    position: absolute; 
    left: -48px;
}  */

.ant-cascader-menu li {
    font-size: 16px;
}

.ant-cascader-menu {
    height: 40px;
}
/* .custom-cascader-popup {
    height: 300px;
} */