.ant-card-head-wrapper {
    position: absolute;
    left: 20px;
    margin-bottom: 4px;
}

.ant-card-head {
    border-bottom: none !important;
}

.mobile .ant-card-head-wrapper {
    position: absolute;
    left: 0px;
    margin-bottom: 4px;
}
.chi-tiet {
    max-width: 80%;
}
.chi-tiet-mobile {
    max-width: 70%;
}