.ant-checkbox-group {
    display: grid;
    font-family: 'Quicksand';
    font-size: 20px;
}
.ant-checkbox-wrapper span {
    font-family: 'Quicksand';
    font-size: 16px;
    font-weight: 500;
}

.ant-radio-button-wrapper {
    width: 120px;
}
