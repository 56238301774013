#main-slider-container{
    width: 100%;
    height: 305px;
}

#slider{
    width: 100%;
    height: 100%;
    white-space: nowrap;
    overflow-x: scroll;
    scrollbar-width: none;
    scroll-behavior: smooth;
}

#slider::-webkit-scrollbar{
    display: none;
}

#main-slider-container {
    height: 100%;
    position: relative;
    display: flex;
    align-items: center;
}


.slider-card-title, .slider-card-description{
    margin: 5px 0px 5px 10px;
}

.slider-card-title{
    font-weight: 500;
    margin-top: 10px;
    overflow: hidden; /* Ẩn nội dung vượt quá kích thước của phần tử */
    text-overflow: ellipsis;
}

.slider-card-description{
    opacity: 0.5;
    font-size: 13px;  
    display: none;
    font-family: 'Quicksand';
    font-weight: bold;
}

.slider-card-image{
    /* position: absolute; */
    width: auto;
    height: 80%;
    top: 50%; /* Đẩy img lên trên 50% của div cha */
    /* transform: translateY(-50%); */
    background-color: rgb(240 240 240 / 80%);
    border-top-left-radius: 10px;
    border-top-right-radius: 10px;
}

.slider-icon.left, .slider-icon.right {
    background: white;
    border-radius: 100%;
    position: absolute;
    opacity: 0.5;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
}

.slider-icon.left{
    left: 0;
    display: none;
}

.slider-icon.right{
    right: 0;
    display: none;
}

.slider-icon.left:hover, .slider-icon.right:hover{
    opacity: 1;
}

.slider-card{
    position: relative;
    width: 240px;
    height: 160px;
    background: white;
    border-radius: 10px;
    display: inline-block;
    margin-left: 5px;
    margin-right: 5px;
    box-shadow: 2px 2px 2px 2px rgb(0 0 0 / 12%);
    cursor: pointer;
    align-items: center;
}
