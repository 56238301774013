@media (max-width: 767px) {
    .popup .ant-modal-content {
        position: fixed;
        top: 20%;
        padding: 0;
        height: 20vh;
    }

    .popup .ant-modal-body {
        display: flex;
        /* height: 100vh; */
    }
}

@media (min-width: 768px) {
    .popup .ant-modal-content {
        position: relative;
        padding: 0;
    }

    .popup .ant-modal-body {
        display: block;
        height: auto;
    }
}
