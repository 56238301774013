@media (max-width: 767px) {
    .modalSuccess .ant-modal-content {
        position: fixed;
    }

    .modalSuccess .ant-modal-body {
        display: flex;
        height: 100vh;
    }
}

@media (min-width: 768px) {
    .modalSuccess .ant-modal-content {
        position: relative;
    }

    .modalSuccess .ant-modal-body {
        display: block;
        height: auto;
    }
}
