@tailwind base;
@tailwind components;
@tailwind utilities;

html {
    padding: 0;
    margin: 0;
    font-family: "Quicksand";
}

.ant-btn {
    background-color: #006d38;
    color: #fff;
}

.ant-btn:hover {
    background-color: #fff;
}
.pause-btn {
    color: #fff;
    background-color: #2d80e9;
}

.ant-typography {
    font-family: "Quicksand";
}

.ant-picker-dropdown .ant-picker-cell:not(.ant-picker-cell-disabled) {
    color: #000;
}
