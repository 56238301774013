* {
    font-family: "Quicksand";
}
label {
    font-family: "Quicksand";
    font-size: 16px;
    font-weight: 700;
}

label a {
    color: #006d38;
}
label a:hover {
    color: #006d38;
}
.ant-input {
    border-radius: 0;
    height: fit-content;
    font-size: 18px;
}

.ant-picker {
    border-radius: 0;
    height: fit-content;
    font-size: 18px;
}
.ant-select .ant-cascader {
    border-radius: 0;
}
.ant-cascader-select {
    border-radius: 0;
}

.stick-slider {
    position: relative;
}

.news-carousel .slick-dots {
    position: absolute;
    top: 215px;
}

.slick-dots li {
    border-radius: 10px;
    background-color: #006d38;
}

/* .ticket input {
    justify-content: center;
    align-items: center;
} */
.ticket {
    text-align: center;
}

/* .ticket .ant-input {
    margin:0px 30px 0px 30px !important;
} */

@media (max-width: 767px) {
    .ant-modal {
        top: 0;
        max-width: 100vh !important;
        padding: 0;
        margin: 0 !important;
    }

    .ant-modal-content {
        position: fixed;
        inset: 0;
        width: 100vw;
        height: 100vh;
        border-radius: 0 !important;
    }
}

@media (min-width: 768px) {
    .ant-modal-content {
        border-radius: 8px;
    }
}
